/**
 * Theme Mode Switch
 * Switch betwen light/dark mode. The chosen mode is saved to browser's local storage
 */

const themeModeSwitch = (() => {
  let mode = window.localStorage.getItem('mode');
  let root = document.getElementsByTagName('html')[0];

  let modeSwitch = document.querySelector('[data-bs-toggle="mode"]');

  if (mode !== null && mode === 'dark') {
    root.classList.add('dark-mode');
  } else {
    root.classList.remove('dark-mode');
  }

  if (modeSwitch === null) return;

  let checkbox = modeSwitch.querySelector('.form-check-input');

  if (mode === 'dark') {
    root.classList.add('dark-mode');
    checkbox.checked = true;
  } else {
    root.classList.remove('dark-mode');
    checkbox.checked = false;
  }

  modeSwitch.addEventListener('click', (e) => {
    if (checkbox.checked) {
      root.classList.add('dark-mode');
      window.localStorage.setItem('mode', 'dark');
    } else {
      root.classList.remove('dark-mode');
      window.localStorage.setItem('mode', 'light');
    }
  });

});

document.addEventListener("load", themeModeSwitch)
document.addEventListener("turbo:load", themeModeSwitch)

export default themeModeSwitch;
